import { useForm, Controller } from 'react-hook-form';
import useAppState from 'app/hooks/useAppState';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
//Local
import { IS_TEXT, IS_EMAIL, IS_US_PHONE } from 'app/constants/regex';
import { FUEL_LABEL } from 'app/constants/strings';
const SecondStep = () => {
	const { appState, setAppState, handleBack } = useAppState();
	const {
		handleSubmit,
		control,
		formState: { isValid, errors },
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			firstName: appState?.firstName || '',
			lastName: appState?.lastName || '',
			email: appState?.email || '',
			phone: appState?.phone || '',
			fuel: appState?.fuel || '',
		},
	});

	const onSubmit = async formData => {
		if (formData)
			setAppState({
				...appState,
				...formData,
				activeStep: appState.activeStep + 1,
			});
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={6}>
					<Controller
						name="firstName"
						control={control}
						rules={{
							required: true,
							pattern: {
								value: IS_TEXT,
								message: 'Invalid name format',
							},
						}}
						render={({ field }) => (
							<TextField
								variant="standard"
								margin="normal"
								fullWidth
								label="First Name"
								name="firstName"
								placeholder="Your first name"
								error={errors.firstName}
								helperText={errors?.firstName?.message || ''}
								{...field}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Controller
						name="lastName"
						control={control}
						rules={{
							required: true,
							pattern: {
								value: IS_TEXT,
								message: 'Invalid last name format',
							},
						}}
						render={({ field }) => (
							<TextField
								variant="standard"
								margin="normal"
								fullWidth
								label="Last Name"
								name="lastName"
								placeholder="Your last name"
								error={errors.lastName}
								helperText={errors?.lastName?.message || ''}
								{...field}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Controller
						name="email"
						control={control}
						rules={{
							required: true,
							pattern: {
								value: IS_EMAIL,
								message: 'Invalid email',
							},
						}}
						render={({ field }) => (
							<>
								<TextField
									variant="standard"
									margin="normal"
									fullWidth
									label="Email"
									name="email"
									placeholder="Your email address"
									error={errors.email}
									helperText={errors?.email?.message || ''}
									{...field}
								/>
							</>
						)}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Controller
						name="phone"
						control={control}
						rules={{
							required: true,
							pattern: {
								value: IS_US_PHONE,
								message: 'Invalid phone',
							},
						}}
						render={({ field }) => (
							<TextField
								variant="standard"
								margin="normal"
								fullWidth
								label="Phone"
								name="phone"
								placeholder="Your phone number"
								error={errors.phone}
								helperText={errors?.phone?.message || ''}
								{...field}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={12} sm={12}>
					<Controller
						name="fuel"
						control={control}
						rules={{
							required: true,
						}}
						render={({ field }) => (
							<TextField
								variant="standard"
								margin="normal"
								fullWidth
								label="Vehicle Fuel"
								name="fuel"
								select
								SelectProps={{
									native: true,
								}}
								error={errors.fuel}
								helperText={errors.fuel && 'Select one fuel'}
								{...field}>
								<option value=""></option>
								{appState.fuelsInSelectedLocation.map(fuel => (
									<option value={fuel}>{FUEL_LABEL[fuel]}</option>
								))}
								{/* <option value={FUEL_VALUES.REGULAR}>{FUEL_LABEL[FUEL_VALUES.REGULAR]}</option>
								<option value={FUEL_VALUES.PREMIUM}>{FUEL_LABEL[FUEL_VALUES.PREMIUM]}</option>
								<option value={FUEL_VALUES.DIESEL}>{FUEL_LABEL[FUEL_VALUES.DIESEL]}</option> */}
							</TextField>
						)}
					/>
				</Grid>
			</Grid>

			<Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 5 }}>
				<Button color="primary" onClick={handleBack} sx={{ mr: 1 }}>
					Back
				</Button>
				<Button variant="contained" disabled={!isValid} color="primary" type="submit">
					Next
				</Button>
			</Box>
		</form>
	);
};

export default SecondStep;
