import axios from 'axios';

const axiosBaseOptions = config => {
	const baseURL = 'https://7471-181-164-130-211.sa.ngrok.io/'; //'http://localhost:8083/'; //process.env.REACT_APP_BASE_URL;
	return {
		baseURL,
		...config,
	};
};

const request = config => axios.request(axiosBaseOptions(config));

const get = (url = '', config = {}) => request({ url, ...config, method: 'GET' });

const post = (url = '', config) => request({ url, ...config, method: 'POST' });

const put = (url = '', config = {}) => request({ url, ...config, method: 'PUT' });

const patch = (url = '', config = {}) => request({ url, ...config, method: 'PATCH' });

const del = (url = '', config = {}) => request({ url, ...config, method: 'DELETE' });

export { get, post, put, patch, del };
