import { CircularProgress } from '@mui/material';
//Local
import COLORS from 'app/constants/colors';

const LoadingScreen = ({ loadingMessage = 'Loading...' }) => {
	return (
		<div
			style={{
				display: 'flex',
				width: '100%',
				height: '100%',
				alignItems: 'center',
				justifyContent: 'center',
				flexDirection: 'column',
			}}>
			<CircularProgress size={35} />
			<div style={{ paddingTop: '10px', fontSize: 14, fontWeight: 500, color: COLORS.secondary }}>{loadingMessage}</div>
		</div>
	);
};

export default LoadingScreen;
