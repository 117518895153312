import { useState } from 'react';
import useAppState from 'app/hooks/useAppState';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Alert, AlertTitle } from '@mui/material';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
//Local
import OrderService from 'app/services/OrderService';
import LoadingScreen from './LoadingScreen';

const ThirdStep = () => {
	const stripe = useStripe();
	const elements = useElements();
	const { appState, setAppState, handleBack } = useAppState();
	const [errorMessage, setErrorMessage] = useState();
	const [loading, setLoading] = useState(false);

	const handleError = error => {
		setLoading(false);
		setErrorMessage(error.response?.data?.message || error?.message || '');
	};

	const createOrder = async order => {
		return await OrderService.createOrder(order);
	};

	const handleSubmit = async event => {
		event.preventDefault();

		if (!stripe) return;

		setLoading(true);

		const { error: submitError } = await elements.submit();
		if (submitError) {
			handleError(submitError);
			return;
		}

		const { error, paymentMethod } = await stripe.createPaymentMethod({
			elements,
			params: {
				billing_details: {
					name: 'Ez Order',
				},
			},
		});

		if (error) {
			handleError(error);
			return;
		}

		if (!paymentMethod) {
			handleError('The payment method could not be added, please try again.');
			return;
		}

		const formattedPM = {
			isDefault: true,
			type: paymentMethod.type,
			id: paymentMethod.id,
			last4: paymentMethod.card.last4,
			expMonth: paymentMethod.card.exp_month,
			expYear: paymentMethod.card.exp_year,
			brand: paymentMethod.card.brand,
			zipCode: paymentMethod?.billing_details?.postal_code,
		};

		try {
			await createOrder({ ...appState, paymentMethod: formattedPM });
		} catch (err) {
			handleError(err);
			return;
		}

		setAppState({ ...appState, paymentMethod: formattedPM, activeStep: appState.activeStep + 1 });
	};

	return (
		<form onSubmit={handleSubmit}>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={12} style={{ minHeight: '170px' }}>
					<PaymentElement />
				</Grid>
				<Grid item xs={12} sm={12}>
					{loading && <LoadingScreen />}
					{errorMessage && (
						<Alert severity="error">
							<AlertTitle>Error submitting the order</AlertTitle>
							{errorMessage || ''}
						</Alert>
					)}
				</Grid>
			</Grid>

			<Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 5 }}>
				<Button disabled={loading} id="submit" color="primary" onClick={handleBack} sx={{ mr: 1 }}>
					Back
				</Button>
				<Button disabled={loading} variant="contained" color="primary" type="submit">
					Submit
				</Button>
			</Box>
		</form>
	);
};

export default ThirdStep;
